import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import IRootState from "system/types/store/IRootState";
import useConfiguration from "system/hooks/use-configuration";
import { lazy } from "react";
import {
  DocumentsPage,
  PackagesPage,
  EndpointsByStatusPage,
  EndpointsByReasonPage,
  SubjectByReasonPage,
  EndpointRuntimeHistoryPage,
  EndpointSubjectHistory,
  EndpointPage,
  MainPage,
} from "components/pages";
import AskAidenAccess from "system/types/enums/AskAidenAccess";
import { Loadable } from "components/modules/ask-aiden";
import { ScrollToTop } from "components/ui";
import AdminHome from "components/admin/AdminHome";

const AskAidenPage = Loadable(lazy(() => import("components/pages/AskAidenPage")));

const MainRouter = () => {
  const { defaultTenant } = useSelector((state: IRootState) => state.config);
  const { isGlobalAdmin, isFileModuleEnabled, isAskAidenEnabled } = useConfiguration();

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate replace to={`/${defaultTenant}`} />} />
        <Route path="/:tenantId/endpoint/:machineId/history" element={<EndpointRuntimeHistoryPage />} />
        <Route path="/:tenantId/endpoint/:machineId/:time/:subject" element={<EndpointSubjectHistory />} />
        <Route path="/:tenantId/endpoint/:machineId/:time" element={<EndpointPage />} />
        <Route path="/:tenantId/endpoint/:machineId" element={<EndpointPage />} />
        <Route path="/:tenantId/endpointsbystatus/:statusString/:time" element={<EndpointsByStatusPage />} />
        <Route path="/:tenantId/endpointsbyreason/:statusString/:subject/:time" element={<EndpointsByReasonPage />} />
        <Route path="/:tenantId/endpointsbyreason/:statusString/:subject/:time/:message" element={<EndpointsByReasonPage />} />
        <Route path="/:tenantId/subjectbyreason/:subject" element={<SubjectByReasonPage />} />
        <Route path="/:tenantId/packages" element={isGlobalAdmin ? <PackagesPage /> : <Navigate replace to={`/${defaultTenant}`} />} />
        <Route
          path="/:tenantId/documents"
          element={isFileModuleEnabled ? <DocumentsPage /> : <Navigate replace to={`/${defaultTenant}`} />}
        />
        <Route
          path="/:tenantId/askAiden"
          element={isAskAidenEnabled !== AskAidenAccess.NoAccess ? <AskAidenPage /> : <Navigate replace to={`/${defaultTenant}`} />}
        />
        <Route path="/:tenantId/*" element={<MainPage />} />
        <Route path="/*" element={<MainPage />} />
        <Route path="/:tenantId/admin" element={isGlobalAdmin ? <AdminHome /> : <Navigate replace to={`/${defaultTenant}`} />} />
      </Routes>
    </>
  );
};

export default MainRouter;
