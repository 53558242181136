import { SiteInfo } from "system/types/wireTypes";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { configActions } from "system/store/slices/config-slice";
import IRootState from "system/types/store/IRootState";
import { mainActions } from "system/store/slices/main-slice";
import AskAidenAccess from "system/types/enums/AskAidenAccess";

let lastTenantId = 0;

const useConfiguration = () => {
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const { tenants, userInfo, currentTenantId, defaultTenant } = useSelector((state: IRootState) => state.config);
  const currentTenant = useMemo<SiteInfo | null>(() => {
    if (!tenants?.length) {
      return null;
    }

    let neededTenantId: number = 0;
    if (!tenantId || +tenantId < 1) {
      if (!lastTenantId) {
        return null;
      }
      neededTenantId = lastTenantId;
    } else {
      neededTenantId = +tenantId;
    }

    const tenant = tenants.find((x: SiteInfo) => x.siteId === +neededTenantId);
    if (!tenant) {
      lastTenantId = 0;
      return null;
    }

    lastTenantId = tenant.siteId;
    return tenant;
  }, [tenantId, tenants]);

  const isGlobalAdmin = useMemo<boolean>(() => {
    const hasPermissionC = userInfo?.permissions?.includes("C") ?? false;
    const hasPermissionG = userInfo?.permissions?.includes("G") ?? false;
    return hasPermissionC && hasPermissionG;
  }, [userInfo]);

  useEffect(() => {
    if (!currentTenant) {
      return;
    }

    dispatch(configActions.setCurrentTenantId(currentTenant?.siteId ?? 0));
    dispatch(mainActions.setFilters(null));
  }, [currentTenant, dispatch]);

  const isFileModuleEnabled = useMemo<boolean>(() => {
    if (!tenants || !defaultTenant) {
      return false;
    }

    const tenant = tenants.find((x: SiteInfo) => x.siteId === +defaultTenant);
    if (!tenant) {
      return false;
    }

    return tenant.isFileModuleEnabled;
  }, [tenants, defaultTenant]);

  const isAskAidenEnabled = useMemo<AskAidenAccess>(() => {
    if (!tenants || !defaultTenant) {
      return AskAidenAccess.NoAccess;
    }

    const tenant = tenants.find((x: SiteInfo) => x.siteId === +defaultTenant);
    if (!tenant) {
      return AskAidenAccess.NoAccess;
    }

    return tenant.askAidenAccess === 1
      ? AskAidenAccess.PromptsAccess
      : tenant.askAidenAccess === 2
      ? AskAidenAccess.FullAccess
      : AskAidenAccess.NoAccess;
  }, [tenants, defaultTenant]);

  const hasPermissionU = currentTenant?.isAdmin ?? false;
  const hasPermissionC = userInfo?.permissions?.includes("C") ?? false;
  const hasPermissionG = userInfo?.permissions?.includes("G") ?? false;
  const hasPermissionR = userInfo?.permissions?.includes("R") ?? false;

  return {
    currentTenant,
    tenants,
    userInfo,
    isGlobalAdmin,
    currentTenantId,
    isFileModuleEnabled,
    isAskAidenEnabled,
    hasPermissionU,
    hasPermissionC,
    hasPermissionG,
    hasPermissionR
  };
};

export default useConfiguration;
